import React from "react";
import { useForm } from "react-hook-form";
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from '../../../config/theme';
import TagManager from 'react-gtm-module'

const Form = styled.form`
    /* border: 1px blue dashed; */
    width: auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    ${media.greaterThan("large")`
    margin-left: 200px;
    `}
    `;

const Label = styled.label`
    /* border: 1px blue dashed; */
    width: auto;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 129.5%;
    letter-spacing: 0.18em;
    color: #FFFFFF;
    margin-bottom: 8px;
    margin-top: 16px;
    ${media.lessThan("medium")`
    align-self: center;
    `}
    ${media.between("medium", "large")`
    align-self: center;
    `}
    ${media.greaterThan("large")`
    /* margin-left: 200px; */
    `}
`;

const FormBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  ${media.lessThan("medium")`
    flex-direction: column;
    `}
    ${media.between("medium", "large")`
    flex-direction: column;
    `}
`;

const Input = styled.input`
    /* border: 1px blue dashed; */
    width: 270px;
    height: 49.65px;
    outline: none!;
    border: 1px solid ${HBTurq};
    outline: none;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    background-color: #000000;
    box-sizing: border-box;
    padding: 10px;
    :focus {
        border: 3px solid ${HBTurq};
      }
    ${media.lessThan("medium")`
      width: 260px;
      align-self: center;
    `}
    ${media.between("medium", "large")`
      width: 250px;
      align-self: center;
    `}
    ${media.greaterThan("large")`
    /* margin-left: 200px; */
    `}
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    width: 65px;
    height: 45px;
    color: ${HBTurq};
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 16px;
    align-self: center;
    transition-duration: 0.5s;
    &:hover{
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        transform: scale(1.05);
        }
    ${media.lessThan("medium")`
    margin-top: 15px;
    `}
    ${media.between("medium", "large")`
      margin-top: 15px;
    `}
    ${media.greaterThan("large")`
      margin-left: 10px;
    `}
`;

const Error = styled.p`
    /* border: 1px blue dashed; */
    color: red;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 20.5%;
    letter-spacing: 0.05em;
    margin-bottom: 12px;
    ${media.lessThan("medium")`
      align-self: center;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
`;

const HiddenError = styled(Error)`
    /* border: 1px blue dashed; */
    color: transparent;
`;

const Success = styled.p`
    /* border: 1px blue dashed; */
    color: green;
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 129.5%;
    letter-spacing: 0.05em;
    ${media.lessThan("medium")`
      align-self: center;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
`;

 export default function SubscribeEmail() {

    const { 
        register, 
        handleSubmit, 
        reset,
        formState: { errors, isSubmitSuccessful }
    } = useForm({reValidateMode: "onSubmit"});

    const onSubmit = async (data) => {

      try {
        let result = await fetch(
            "https://mxwoz7z2jj.execute-api.us-east-2.amazonaws.com/prod/newslettersignup",
          {
            method: "post",
            mode: "no-cors",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              email: data.email
            }),
          }
        );

        reset({  email: ''});
        TagManager.initialize(tagSubscribed)
      } catch (e) {
        console.log("BIG ERROR", e);
      }
    };

  return (
    <div>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Label>JOIN OUR MAILING LIST:</Label>
              {errors.email ? <Error>Email required and must be valid</Error>: <HiddenError>This is an error</HiddenError>}
            
            <FormBtnContainer>
            {/* <div> */}
              <Input type="text" name="email" placeholder="Email" {...register('email', {required: true, maxLength: 30, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} />
              <Button type="submit">GO</Button>
            {/* </div> */}
            </FormBtnContainer>
               {(isSubmitSuccessful) ? <Success>Message Sent</Success> : null}

        </Form>
    </div>
  );
}


const tagSubscribed = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'hearbeyond_email_subscribed'
  }
}